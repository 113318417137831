import React, { useState, useEffect } from 'react';

function Mapp() {
  const [data, setData] = useState("one");
  // /  const [weatheri, setWeatheri] = useState(1);


  return (
    <div class="map"></div>
  );
}

export default Mapp;
