import React from 'react';

function savings() {
  return (
    <div>
      <p>Savings applet to show real time: grid price - traded price</p>
    </div>
  );
}

export default savings;